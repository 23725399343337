// nav, #footer { display: none; margin: 0 }
// #page-wrapper { margin: 0 }

@media print
{
    .tuo {
        border-bottom: none !important;
    }
    .label
    {
        border: none !important;
        font-size: 1.1em !important;
    }
    #monthly-paperwork-wrapper
    {
        overflow-x: hidden !important;
    }
    body
    {
        font-size: .95em;

        height: 98%;
    }
    @page
    {
        margin: .25in;
        size: landscape;
    }

    .dataTables_length,
    .dataTables_filter,
    .pagination,
    nav,
    #footer,
    div.row.table-row > div.no-float.left,
    div.row.table-row > div.no-float.right,
    .submit-button,
    .breadcrumb
    {
        display: none !important;
    }

    .print-header
    {
        font-size: 1.9em;
    }

    table.table > thead > tr > th,
    table.table > tbody > tr > td,
    table.table > tfoot > tr > td
    {
        color: #000 !important;
        background-color: #fff !important;
    }

.report-cfs {
	text-align: center;
	text-shadow: none !important;
	background-color: #309E43 !important;
	color: #eee !important;
}

.report-cfs-total {
	text-align: center;
	text-shadow: none !important;
	background-color: #257A34 !important;
	color: #eee !important;
}

.report-cash-drawer {
	text-align: center;
	text-shadow: none !important;
	background-color: #2F5B9F !important;
	color: #eee !important;
}

.report-cash-drawer-total {
	text-align: center;
	text-shadow: none !important;
	background-color: #244B7B !important;
	color: #eee !important;
}

.report-over-short {
	text-align: right;
	box-shadow: inset 30px 0 9px -30px rgba(0,0,0,0.6);
	text-shadow: none !important;
	background-color: #166fda !important;
	color: #eee !important;
}

    .form_push_btn, .form_push_btn_stretch
    {
        color: #eee !important;
        border: 0 !important;
        font-size: 1.7em !important;
        background-color: black !important;
        text-shadow: 0 !important;
    }

    div.datatTables_wrapper > div.row:first-of-type,
    .hide-print,
    hr
    {
        display: none !important;
    }

    h1.heading {
        font-size: 2em !important;
        color: #000 !important;
        padding: 10px !important;
        margin-top: 50px !important;
        background-color: #fff !important;
    }

    table.table > thead > tr > th:after
    {
        content: none !important;
    }


    table
    {
        float: none !important;
    }
    div
    {
        float: none !important;
    }
    .dont-print
    {
        display: none;
    }
    #wrap
    {
        display: none;
    }

    .label-inverse-link
    {
        margin: 0 !important;
        padding: 0 !important;
    }

    input[type=checkbox]
    {
        line-height: 2em !important;
        /* Webkit browsers*/

        -webkit-transform: scale(.8);
        /* All browsers except webkit*/
                transform: scale(.8);
        vertical-align: middle;
    }

    .btn-xs,
    .btn-group-xs > .btn
    {
        line-height: .9em !important;

        margin: 0 !important;
        padding: 0 !important;
    }

    .btn
    {
        font-size: .9em;
        line-height: .9em !important;

        display: inline !important;

        margin: 0 !important;
        padding-top: 0 !important;
        padding-right: 3px !important;

        border: 0 !important;
    }

    @media print
    {
        a[href]:after
        {
            content: none !important;
        }
    }
    .nosplit
    {
        page-break-inside: avoid;
    }
    div#content
    {
        overflow: visible;
    }
}

@media print
{
    * {
        transition: none !important;
    }
    body
    {
        font-size: .90em;

        height: 70%;

        -webkit-print-color-adjust: exact !important;
    }

    .sorting,
    .align-left,
    .align-right
    {
        font-size: 1.7em !important;
    }

    .font-adjust
    {
        font-size: 1.3em !important;
    }
    @page
    {margin: .25in;

        size: landscape;
    }
    nav,
    #footer
    {
        display: none;
    }

    .print-header
    {
        font-size: 1.9em;
    }

    table
    {
        float: none !important;
    }
    div
    {
        float: none !important;
    }
    .dont-print
    {
        display: none;
    }
    #wrap
    {
        display: none;
    }

    .label-inverse-link
    {
        margin: 0 !important;
        padding: 0 !important;
    }

    input[type=checkbox]
    {
        line-height: 2em !important;
        /* Webkit browsers*/

        -webkit-transform: scale(.8);
        /* All browsers except webkit*/
                transform: scale(.8);
        vertical-align: middle;
    }

    .btn-xs,
    .btn-group-xs > .btn
    {
        line-height: .9em !important;

        margin: 0 !important;
        padding: 0 !important;
    }

    .btn
    {
        font-size: .9em;
        line-height: .9em !important;

        display: inline !important;

        margin: 0 !important;
        padding-top: 0 !important;
        padding-right: 3px !important;

        border: 0 !important;
    }

    @media print
    {
        a[href]:after
        {
            content: none !important;
        }
    }
    .nosplit
    {
        page-break-inside: avoid;
    }
    div#content
    {
        overflow: visible;
    }

    .ticket-created,
    .report-cfs,
    .report-cfs-total,
    .report-cash-drawer,
    .report-cash-drawer-total
    {
        color: black !important;
        background-color: white !important;
    }
}
