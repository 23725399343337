h1.heading,
h2.heading,
h3.heading,
h4.heading
{
    display: inline-block;

    width: 100%;
    margin: 15px 0;
    padding: 15px 0;

    text-align: center;

    color: #eee!important;
    background-color: #003987;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,.25);
}

h1.heading > small,
h2.heading > small,
h3.heading > small,
h4.heading > small
{
    font-size: 45%;

    color: #eee;
}

#page-wrapper
{
    padding: 0 15px;

    border: 0 !important;
    background-color: #fff;
}

@media (min-width: 768px)
{
    #page-wrapper
    {
        padding-right: 330px;
    }
}
.navbar-static-side ul li
{
    border-bottom: 1px solid #e7e7e7;
}

/* Navigation */
/* ------------------------------- */
/* Top Right Navigation Dropdown Styles */
.navbar-top-links li
{
    display: inline-block;
}

.navbar-top-links li:last-child
{
    margin-right: 15px;
}

.navbar-top-links li a
{
    min-height: 50px;
    padding: 15px;
}

.navbar-top-links .dropdown-menu li
{
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child
{
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a
{
    min-height: 0;
    padding: 3px 20px;
}

.navbar-top-links .dropdown-menu li a div
{
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts
{
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages
{
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks
{
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts
{
    margin-left: -123px;
}

.navbar-top-links .dropdown-user
{
    right: 0;
    left: auto;
}

/* Sidebar Menu Styles */
.sidebar-search
{
    padding: 15px;
}

.arrow
{
    float: right;
}

.fa.arrow:before
{
    content: '\f104';
}

.active > a > .fa.arrow:before
{
    content: '\f107';
}

.nav-second-level li,
.nav-third-level li
{
    border-bottom: none !important;
}

.nav-second-level li a
{
    padding-left: 37px;
}

.nav-third-level li a
{
    padding-left: 52px;
}

@media (min-width: 768px)
{
    .navbar-static-side
    {
        position: absolute;
        z-index: 1;

        width: 250px;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts
    {
        margin-left: auto;
    }
}
/* Buttons */
/* ------------------------------- */
.btn-outline
{
    transition: all .5s;

    color: inherit;
    background-color: transparent;
}

.btn-primary.btn-outline
{
    color: #428bca;
}

.btn-success.btn-outline
{
    color: #5cb85c;
}

.btn-info.btn-outline
{
    color: #5bc0de;
}

.btn-warning.btn-outline
{
    color: #f0ad4e;
}

.btn-danger.btn-outline
{
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover
{
    color: #fff;
}

/* Pages */
/* ------------------------------- */
/* Dashboard Chat */
.chat
{
    margin: 0;
    padding: 0;

    list-style: none;
}

.chat li
{
    margin-bottom: 10px;
    padding-bottom: 5px;

    border-bottom: 1px dotted #b3a9a9;
}

.chat li.left .chat-body
{
    margin-left: 60px;
}

.chat li.right .chat-body
{
    margin-right: 60px;
}

.chat li .chat-body p
{
    margin: 0;

    color: #777;
}

.panel .slidedown .glyphicon,
.chat .glyphicon
{
    margin-right: 5px;
}

.chat-panel .panel-body
{
    overflow-y: scroll;

    height: 350px;
}

/* Login Page */
.login-panel
{
    margin-top: 25%;
}

/* Flot Chart Containers */
.flot-chart
{
    display: block;

    height: 400px;
}

.flot-chart-content
{
    width: 100%;
    height: 100%;
}

/* DataTables Overrides */
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled
{
    background: transparent;
}

table.dataTable thead .sorting_asc:after
{
    font-family: fontawesome;

    float: right;

    content: '\f0de';
}

table.dataTable thead .sorting_desc:after
{
    font-family: fontawesome;

    float: right;

    content: '\f0dd';
}

table.dataTable thead .sorting:after
{
    font-family: fontawesome;

    float: right;

    content: '\f0dc';

    color: rgba(50, 50, 50, .5);
}

/* Circle Buttons */
.btn-circle
{
    font-size: 12px;
    line-height: 1.428571429;

    width: 30px;
    height: 30px;
    padding: 6px 0;

    text-align: center;

    border-radius: 15px;
}

.btn-circle.btn-lg
{
    font-size: 18px;
    line-height: 1.33;

    width: 50px;
    height: 50px;
    padding: 10px 16px;

    border-radius: 25px;
}

.btn-circle.btn-xl
{
    font-size: 24px;
    line-height: 1.33;

    width: 70px;
    height: 70px;
    padding: 10px 16px;

    border-radius: 35px;
}

.show-grid [class^='col-']
{
    padding-top: 10px;
    padding-bottom: 10px;

    border: 1px solid #ddd;
    background-color: #eee !important;
}

.show-grid
{
    margin: 15px 0;
}
