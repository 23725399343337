.push_btn
{
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 30px;

    position: relative;

    display: inline-block;

    width: 100%;
    height: 150px;
    margin: 2px;

    cursor: pointer;
    transition: all .5s;
    text-align: center;
    text-decoration: none;

    color: #fff;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    background: #1b558f;
    box-shadow: inset 0 0 0 transparent;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.push_btn:hover
{
    text-decoration: none;

    color: #fff !important;
    -webkit-border-radius: 18px;
       -moz-border-radius: 18px;
            border-radius: 18px;
    background: #82bf56;
    -webkit-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
       -moz-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
            box-shadow: inset 0 0 0 transparent;
            box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}

.form_push_btn
{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 2.8em;

    position: relative;

    display: inline-block;

    width: 200px;
    height: 3em;
    margin: 2px;

    cursor: pointer;
    transition: all .5s;
    text-align: center;
    text-decoration: none;

    color: #fff;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    background: #1b558f;
    box-shadow: inset 0 0 0 transparent;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.form_push_btn:hover
{
    text-decoration: none;

    color: #fff !important;
    -webkit-border-radius: 18px;
       -moz-border-radius: 18px;
            border-radius: 18px;
    background: #82bf56;
    -webkit-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
       -moz-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
            box-shadow: inset 0 0 0 transparent;
            box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}

.form_push_btn_stretch
{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 2.8em;

    position: relative;

    display: inline-block;

    width: 100%;
    height: 3em;
    margin: 2px;

    cursor: pointer;
    transition: all .5s;
    text-align: center;
    text-decoration: none;

    color: #fff;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    background: #1b558f;
    box-shadow: inset 0 0 0 transparent;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.form_push_btn_stretch:hover
{
    text-decoration: none;

    color: #fff !important;
    -webkit-border-radius: 18px;
       -moz-border-radius: 18px;
            border-radius: 18px;
    background: #82bf56;
    -webkit-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
       -moz-box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
            box-shadow: inset 0 0 0 transparent;
            box-shadow: 12px 11px 11px -3px rgba(0,0,0,.31);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}


.form_push_btn_stretch.push_small
{
    width: auto;
    border-radius: 50%;
    padding: 2px 10px;
    height: auto;
    line-height: 1.1em;
    font-size: 1em;
}

.push_md
{
    width: auto;
    border-radius: 5%;
    padding: 8px 13px;
    height: auto;
    line-height: 1.1em;
    font-size: 1em;
    &:hover
    {
        border-radius: 8%;
        -webkit-box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
           -moz-box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
                box-shadow: inset 0 0 0 transparent;
                box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
    }
}

.push_small
{
    width: auto;
    border-radius: 5%;
    padding: 5px 10px;
    height: auto;
    line-height: 1.1em;
    font-size: 1em;
    &:hover
    {
        border-radius: 8%;
        -webkit-box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
           -moz-box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
                box-shadow: inset 0 0 0 transparent;
                box-shadow: 6px 5px 5px -3px rgba(0,0,0,.31);
    }
}

.push_xs
{
    width: auto;
    border-radius: 5%;
    padding: 2px 5px;
    height: auto;
    line-height: 1em;
    font-size: .95em;
    &:hover
    {
        border-radius: 8%;
        -webkit-box-shadow: 3px 2px 2px -2px rgba(0,0,0,.31);
           -moz-box-shadow: 3px 2px 2px -2px rgba(0,0,0,.31);
                box-shadow: inset 0 0 0 transparent;
                box-shadow: 3px 2px 2px -2px rgba(0,0,0,.31);
    }
}


.tuo
{
    color: #eee;
    border-bottom: 5px solid darken($company_primary, 10%);
    background-color: $company_primary;
    text-shadow: 0 -2px darken($company_primary, 10%);
    &:hover,
    &:focus,
    &:active
    {
        color: #eee;
        border-bottom: 5px solid $company_primary;
        background-color: lighten($company_primary, 10%);
    }
    &:focus
    {
        color: #fff;
    }
}

.tuo_primary_alt
{
    color: #eee;
    border-bottom: 5px solid darken($company_primary_alt, 10%);
    background-color: $company_primary_alt;
    text-shadow: 0 -2px darken($company_primary_alt, 10%);
    &:hover,
    &:focus,
    &:active
    {
        border-bottom: 5px solid $company_primary_alt !important;
        background-color: lighten($company_primary_alt, 10%) !important;
    }
    &:focus
    {
        color: #eee;
    }
}

.push_disabled
{
    color: #eee !important;
    border-bottom: 5px solid darken(#9f9f9f, 10%);
    background-color: #9f9f9f;
    text-shadow: 0 -2px darken(#9f9f9f, 10%);
    &:hover,
    &:focus,
    &:active
    {
        color: #eee;
        border-bottom: 5px solid #9f9f9f !important;
        background-color: lighten(#9f9f9f, 10%) !important;
        text-shadow: 0 -2px darken(#9f9f9f, 5%);
    }
    &:focus
    {
        color: #eee;
    }
}

.push_success
{
    color: #eee !important;
    border-bottom: 5px solid darken($company_success, 10%);
    background-color: $company_success;
    text-shadow: 0 -2px darken($company_success, 10%);
    &:hover,
    &:focus,
    &:active
    {
        color: #eee;
        border-bottom: 5px solid $company_success !important;
        background-color: lighten($company_success, 10%) !important;
        text-shadow: 0 -2px darken($company_success, 5%);
    }
    &:focus
    {
        color: #eee;
    }
}


.push_danger
{
    color: #eee;
    border-bottom: 5px solid darken($company_danger, 10%);
    background-color: $company_danger;
    text-shadow: 0 -2px darken($company_danger, 10%);
    &:hover,
    &:focus,
    &:active
    {
        color: #fff !important;
        border-bottom: 5px solid $company_danger !important;
        background-color: lighten($company_danger, 5%) !important;
        text-shadow: 0 -2px darken($company_danger, 5%);
    }
    &:focus
    {
        color: #eee;
    }
}


.push_comment
{
    color: #eee;
    padding: 7px !important;
    background-color: $company_primary;
    text-shadow: 0 -2px darken($company_primary, 10%);
    &:hover,
    &:focus,
    &:active
    {
        background-color: lighten($company_primary, 10%) !important;
        text-shadow: 0 -2px darken($company_primary, 5%);
    }
    &:focus
    {
        color: #eee;
    }
}


.push_info
{
    color: #eee;
    border-bottom: 5px solid darken($company_info, 10%);
    background-color: $company_info;
    text-shadow: 0 -2px darken($company_info, 10%);
    &:hover,
    &:focus,
    &:active
    {
        border-bottom: 5px solid $company_info !important;
        background-color: lighten($company_info, 10%) !important;
        text-shadow: 0 -2px darken($company_info, 5%);
    }
    &:focus
    {
        color: #eee;
    }
}
