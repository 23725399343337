form .form-control:active
{
    color: #eee !important;
    border: 3px solid #d9534f !important;
    background-color: #d9534f !important;
    box-shadow: none !important;
}

input[type=search]
{
    font-size: 1.4em !important;
    line-height: 1.4em !important;
}

form input:focus,
select:focus,
textarea:focus,
input:focus,
.select2-input
{
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
         -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;

    color: #eee !important;
    border-color: #d9534f !important;
    outline: none !important;
    background: none !important;
    background-color: #d9534f !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 10px #d9534f !important;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 10px #d9534f !important;
}

.popover
{
    color: #333;
}

h1.heading a
{
    font-size: 1.5rem;
    line-height: 2.55em;

    display: block;

    color: #eee;
}
