
table.DTFC_Cloned tr.even {
	background-color: white;
	margin-bottom: 0;
}

div.DTFC_LeftHeadWrapper table,
div.DTFC_RightHeadWrapper table {
	border-bottom: none !important;
	margin-bottom: 0 !important;
	background-color: white;
}

div.DTFC_LeftBodyWrapper table,
div.DTFC_RightBodyWrapper table {
	border-top: none;
	margin: 0 !important;

	// Hide sorting icons
	thead {
		.sorting:after,
		.sorting_asc:after,
		.sorting_desc:after,
		.sorting:after,
		.sorting_asc:after,
		.sorting_desc:after {
			display: none;
		}
	}

	// Header provides the border
	tbody tr:first-child {
		th,
		td {
			border-top: none;
		}
	}
}

div.DTFC_LeftFootWrapper table,
div.DTFC_RightFootWrapper table {
	border-top: none;
	margin-top: 0 !important;
	background-color: white;
}
