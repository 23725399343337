#footer
{
    font-size: 1em;
    font-weight: 700;

    position: fixed;
    z-index: 2;
    bottom: 0;

    width: 100%;
    height: 60px;

    border-top: 1px solid #525252;
    background-color: #393939;
    box-shadow: inset 0 20px 20px -20px #000;;
}

.bottom-nav li a
{
    line-height: 60px;

    padding: 0 15px 0 15px;

    color: #eee !important;
    &:link
    {
        line-height: 60px;

        display: block;

        height: 100%;

        color: #eee !important;
    }
    &:hover
    {
        text-decoration: none;

        color: #eee !important;
        background-color: lighten(#404040, 10%);
        box-shadow: inset 0 20px 20px -20px #000;
    }
}
.bottom-nav li a i
{
    margin-right: 10px;
    margin-bottom: 5px;

    vertical-align: middle;
}

.bottom-nav li
{
    display: inline-block !important;
    &.active
    {
        background-color: lighten($company_primary, 10%);
        box-shadow: inset 0 20px 20px -20px #000;
    }
}

ul.bottom-nav
{
    min-width: 100%;

    -webkit-padding-start: 0;
}
