$company_primary: #003987;
$company_secondary: #e4c702;
$company_primary_alt: #3366ff;
$company_success: #0cb131;
$company_info: #0c80b1;
$company_danger: #c72212;
$breadcrumb-separator: "&raquo;";
$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;
$brand-primary: $company_primary;
$brand-info: $company_primary_alt;
$brand-info-text: #eee;
$state-info-text: #eee;
$state-info-bg: $company_primary;


$notification-colors-red: #ed4747;
$notification-colors-orange: #fa8100;
$notification-colors-green: #47b101;
$notification-colors-blue: #0099d2;
$notification-colors-white: #fff;

$notification-border-radius: 4px;
$notification-padding: 10px;
$notification-marggin: 0 0 10px 0;
