.sidebar
{
    display: block;
    float: left;

    width: 250px;

    background: #333;
}
.metismenu .arrow
{
    top: 20px;
}
.content
{
    display: block;
    overflow: hidden;

    width: auto;
}
.sidebar-nav
{
    width: 100%;

    background-image: -webkit-linear-gradient(left, color-stop(#333 10px), color-stop(#222 10px));
    background-image:         linear-gradient(to right, #333 10px, #222 10px);
    background-repeat: repeat-x;
}
.sidebar-nav ul
{
    margin: 0;
    padding: 0;

    list-style: none;
}
.sidebar-nav a,
.sidebar-nav a:hover,
.sidebar-nav a:focus,
.sidebar-nav a:active
{
    outline: none;
}
.sidebar-nav ul li,
.sidebar-nav ul a
{
    display: block;
    font-size: 1em;
}
.sidebar-nav li .fa
{
    margin: 0 0 0 10px;
    font-size: 1.4em;
    vertical-align: middle;
}
.sidebar-nav ul a
{
    line-height: 10px;

    padding: 20px 10px;

    color: #aaa;
    border-top: 1px solid rgba(0, 0, 0, .3);
    box-shadow: 0 1px 0 rgba(255, 255, 255, .05) inset;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
}
.sidebar-nav ul a:hover,
.sidebar-nav ul a:focus,
.sidebar-nav ul a:active,
.sidebar-nav ul > li > a.active
{
    text-decoration: none;

    color: #fff;
}
.sidebar-nav ul ul a
{
    padding: 5px 10px;

    background-color: rgba(255, 255, 255, .1);
}

.sidebar-nav ul > li a.active.report-link
{
    font-weight: bold;

    color: #eee;
    background-color: $company_success !important;
    box-shadow: inset 0 20px 20px -20px #000;
    text-shadow: 2px 2px 1px rgba(0,0,0,.2);
}

.sidebar-nav ul > li a.active.admin-link
{
    color: #eee;
    background-color: $company_danger !important;
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
    text-shadow: 2px 2px 1px rgba(0,0,0,.5);
}
.sidebar-nav ul ul a:hover,
.sidebar-nav ul ul a:focus,
.sidebar-nav ul ul a:active,
.sidebar-nav ul > li > a.active
{
    background-color: $company_primary;
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
    text-shadow: 2px 2px 1px rgba(0,0,0,.8);
}
.sidebar-nav-item
{
    padding-left: 5px;
}
.sidebar-nav-item-icon
{
    padding-right: 5px;
}
#rtlh3 small
{
    display: inline-block;

    transform: rotateY(180deg);
}

@media (min-width: 768px)
{
    .navbar-static-metis
    {
        position: absolute;
        z-index: 1;

        width: 250px;
    }
}
